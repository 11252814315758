import { Theme } from "style/Theme";

export const Dark: Theme = {
    brand: "#7933ff",
    brandPastel: "#8f7ab8",
    sidebar: "#0b0020",
    background: "#04000c",
    text: {
        title: "#faf9fb",
        subtitle: "#eeeeee",
        emphasis: "#d7d7db",
        normal: "#faf9fb",
        subtle: "#a4a4ac",
        shadow: "#eeeeee"
    }
};
