import * as React from "react";

interface Props {
    title: string;
}

export const Logo: React.FC<Props> = props => (
    <svg version="1.1" viewBox="0 0 512 257.09" xmlns="http://www.w3.org/2000/svg">
        <title>{props.title}</title>
        <g transform="translate(-1.7,-135.31)">
            <path d="m77.1 218.05v-58.71h155.31v58.71h-40.41v149.4h-72v-149.4z" className="letters" />
            <path
                d={
                    "m403.24 219.29q-43.8-4.65-63.67-4.66t-23.14 1.09a4.36 4.36 0 0 0-3.26 4.5c0 2.29 1.24 4 3.72 " +
                    "5.28s10.3 3.32 23.45 6.22a329.12 329.12 0 0 1 32.31 8.54 78.71 78.71 0 0 1 22.67 11.8 35.84 " +
                    "35.84 0 0 1 13.36 18.64q3.26 11 3.26 29.66 0 38.52-20.5 55t-61.81 16.44a330.94 330.94 0 0 " +
                    "1-41.78-2.8q-21.9-2.81-33.7-5.59l-12.15-2.79 3.1-55q47.52 6.84 78 6.84 16.15 0 " +
                    "16.16-7.15c0-2.07-1.25-3.57-3.73-4.5s-9.22-2.23-20.19-3.88q-43.48-6.84-59.17-" +
                    "21.28t-15.69-47.35q0-41.93 20.19-57.46t62.12-15.53q29.5 0 69.27 5.9l13.05 2.17z"
                }
                className="letters"
            />
            <g>
                <path
                    className="inner-letters"
                    d="m289.13 361.25 3.65-26.65 11.82 1.62-3.6 26.66zm3.87-28.35 1.11-8.07 11.89 1.63-1.11 8.08z"
                />
                <path
                    className="inner-letters"
                    d="m310.67 336.51 12.68 0.34 1.47 17h0.69l2.26-16.85 11.93 0.32-5.24 26.77-20-0.53z"
                />
                <path
                    className="inner-letters"
                    d="m342.81 335.59-2-7.9 11.67-3 2 7.9zm7.13 27.71-6.66-26.07 11.57-3 6.66 26.07z"
                />
                <path
                    className="inner-letters"
                    d={
                        "m374.67 334.71 2.73 4.82a3.2 3.2 0 0 0 1.19 1.38 1.71 1.71 0 0 0 1.45-0.3l2.06-1.23 " +
                        "4.71 8a38.06 38.06 0 0 1-5.65 4.3c-2.87 1.63-5.27 2.2-7.18 " +
                        "1.71s-3.77-2.34-5.59-5.55l-4.1-7.24-1.81 " +
                        "1-4.75-8.39 1.8-1-2.62-4.63 10.38-5.89 2.63 4.64 3.75-2.13 4.76 8.39z"
                    }
                />
                <path
                    className="inner-letters"
                    d={
                        "m392.24 320.61c1.21 0.29 2-0.31 2.43-1.82q1.43-5.13 2.54-10.35l0.4-2.05 7.51 1.81a65.91 " +
                        "65.91 0 0 1-2.26 14q-1.87 " +
                        "6.75-5.81 8.9t-10.9 0.22c-4.63-1.29-7.84-3.33-9.63-6.15s-2-6.51-0.78-11.08 " +
                        "3.18-7.69 5.75-9.38 5.87-2 9.91-0.85l4.94 1.92zm-5.24-5.61a3.94 3.94 0 0 0-2.23-0.14 " +
                        "1.77 1.77 0 0 0-1 1.34 1.73 1.73 0 0 0 0.2 1.66 4.45 4.45 0 0 0 2 1.05z"
                    }
                />
                <path
                    className="inner-letters"
                    d={
                        "m401.79 285.2 2.39 11.69-26.36 5.39-2.39-11.69 " +
                        "2.45-0.5q-3.79-2.65-4.26-4.89l10.86-2.2a11.09 11.09 0 0 0 2.35 4.31l0.66 0.84z"
                    }
                />
                <path
                    className="inner-letters"
                    d={
                        "m158.68 333.54h10.13a3.54 3.54 0 0 0 0.58-1.28l7.57 0.16a44.54 44.54 0 " +
                        "0 1 0.64 5.7 8.43 8.43 0 0 1-0.45 2.82 4.45 4.45 0 0 1-0.94 1.71l-0.48 " +
                        "0.42a16 16 0 0 1 1.87 8q0 4.71-2.16 7.11c-1.44 1.6-3.75 2.4-6.95 " +
                        "2.4s-5.34-0.9-6.42-2.72-1.7-4.67-1.84-8.57l-0.16-3.84h-0.48c-0.67 0-1 0.75-1 " +
                        "2.24a89.89 89.89 0 0 0 0.53 9.69l0.16 1.76-8.1 0.27a50.61 50.61 0 0 " +
                        "1-1.86-12.84q0-6.07 1.81-9.43c1.27-2.4 3.79-3.6 7.55-3.6zm10.71 13.95a3.21 " +
                        "3.21 0 0 0-0.8-2h-2l0.11 1.81c0.07 0.92 0.53 1.39 1.39 1.39s1.3-0.41 1.3-1.2z"
                    }
                />
                <path
                    className="inner-letters"
                    d="m176.91 327.57-26.91 4.53v-12.68l16.94-1.92v-0.75l-16.94-1.7v-12l37.3 6.34v13.11l-10.39-3.36z"
                />
                <path className="inner-letters" d="m176.91 301.57h-36.77v-11.94h36.77z" />
                <path
                    className="inner-letters"
                    d={
                        "m152.64 283.8q-3.33-3.54-3.33-10.68t3.33-10.69q3.33-3.54 10.84-3.54t10.82 3.54q3.3 " +
                        "3.56 3.3 10.69t-3.3 10.68q-3.31 3.54-10.82 3.54t-10.84-3.54zm14.36-9a2 2 0 0 0 0-3.41 " +
                        "13.33 13.33 0 0 0-7.09 0 2 2 0 0 0 0 3.41 13.33 13.33 0 0 0 7.09 0z"
                    }
                />
                <path
                    className="inner-letters"
                    d={
                        "m176.91 244.67v11.93h-26.91v-11.93h2.5q-3.19-3.36-3.19-5.65h11.08a11.21 " +
                        "11.21 0 0 0 1.44 4.69l0.48 1z"
                    }
                />
                <path className="brackets" d="m66.88 181.74-23.88 3.2 23.9 4.3v23.1l-38.6-15.8v-22.1l38.6-15.1z" />
                <path className="brackets" d="m416.12 364.94 20-49.52 14.33 2.9-20 49.53z" />
                <path className="brackets" d="m479 345-23.9-3.2v-22.4l38.6 15.1v22.1l-38.6 15.8v-23.1z" />
            </g>
        </g>
    </svg>
);
