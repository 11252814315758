import { Theme } from "style/Theme";

export const Light: Theme = {
    brand: "#7933ff",
    brandPastel: "#8f7ab8",
    sidebar: "#faf9fb",
    background: "#F5F5F5",
    text: {
        title: "#0b0020",
        subtitle: "#110033",
        emphasis: "#141416",
        normal: "#29292d",
        subtle: "#5e5e68",
        shadow: "#111111"
    }
};
